* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5 !important;
}


.certificate-container {
  width: 850px;
  height: 850px;
  padding: 40px;
  background-image: url('https://images.unsplash.com/photo-1542515073-0a6aefbe0c4f?fit=crop&w=1280&q=80') !important;
  background-size: cover;
  background-position: center;
  position: relative;
  border: 15px solid #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
}

.certificate-frame {
  border: 5px solid #d4af37;
  padding: 20px;
  height: 100%;
  position: relative;
}

.header {
  text-align: center;
  margin: 22px 0;
}

.header img {
  width: 150px;
}

.certificate-content {
  background: rgba(255, 255, 255, 0.8) !important;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 2;
}

h1 {
  font-size: 40px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 18px;
  margin: 10px 0;
  color: #666;
}

.name {
  font-size: 36px;
  font-weight: bold;
  color: #d4af37 !important;
  margin: 20px 0;
}

.signature {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signature div {
  text-align: center;
}

.signature-line {
  border-top: 2px solid #333;
  width: 150px;
  margin-bottom: 10px;
}

.signature-name {
  font-size: 16px;
  color: #333;
}

.footer {
  text-align: center;
  margin-top: 28px;
  font-size: 24px;
  font-weight: 700;
}

.certificate-decoration {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: #d4af37;
  border-radius: 50%;
  z-index: 1;
}
