*{
  padding: 0;
  margin: 0;
}

body {
  background: linear-gradient(135deg, #0c4a80, #062f4f);
  /* background: linear-gradient(60deg, rgb(82, 67, 170), rgb(237, 80, 180)) 0% 0% / auto repeat scroll padding-box border-box rgb(82, 67, 170);; */

}


ul li{
  list-style: none;
  
}

.btn-success{
  background-color: #F2EB0A!important;
  border: none;
color: #111 !important;
}
/* Nav bar  */

.navbar  .navbar-btn{
  background-color:  #F2EB0A !important;
  color: #111 !important;
  text-decoration: none;
  border: none;
  
}



/* Landing Page */

.landing-heading{
  color: #F2EB0A;
  font-size: 36px;
}

.lead{
  color: #fff;
  font-size: 22px;
}

.lead strong{
  color: #F2EB0A !important;
  font-weight: 700;
}

.btn-landing {
  background: #F2EB0A !important;
  width: 100% !important;
  color: #111 !important;
  font-size: 22px !important;
  padding: 8px 6px !important;
}

.btn-landing:hover{
  color: #F2EB0A !important;
  background-color: transparent !important;
  border: 1px solid #F2EB0A !important;
}

.boost-text {
  background-color: #1077aa;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
}

.boost-text strong{
  color: #F2EB0A;
  font-weight: 700;
}

/* About us */

.text-muted{
  color: #fff !important;
}

.lead{
  color: #fff !important;
}


/* next page - 2 */


.card {
  background-color: #0b3a60;
  border: none;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.form-control {
  background-color: #0c4a80;
  color: #c0f0c0;
  border: 1px solid #44b544;
  padding: 15px;
  font-size: 1.1rem;
}

.form-control::placeholder {
  color: #a8d8a8;
  opacity: 1;
}

.btn-success {
  background-color: #44b544;
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 12px 0;
  transition: all 0.3s ease;
}

.btn-success:hover {
  background-color: #3da03d;
}

.text-white {
  color: #c0f0c0 !important;
}

.modal-title-custom {
  color: #ff5722; /* Orange color */
}

.modal-body-custom {
  color: #4caf50; /* Green color */
}




@media (max-width: 576px) {
  .form-control {
      font-size: 1rem;
      padding: 12px;
      width: 100%;
  }

  .btn-success {
      font-size: 1.1rem;
  }

  h2 {
      font-size: 1.5rem;
  }
}

/* salary report  */


.text-white {
  color: #ffffff !important;
}

.text-highlight {
  color: #43c6f1;
}

.circle-container {
  gap: 20px;
}

.circle {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.circle-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  text-align: center;
  padding: 10px;
  font-size: 0.8rem;
}

.overlay p {
  margin: 0;
}

@media (max-width: 576px) {
  .circle {
      width: 80px;
      height: 80px;
  }

  .overlay {
      padding: 5px;
      font-size: 0.7rem;
  }
}

/* Quiz */
.body{
  color: white;
  height: 100vh;
}

.wrappers{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #2d6a91;
  width: 100%;
}

.quiz-container {
  padding: 18px 0;
  max-width: 600px;
  margin: 0 auto;
  background-color: #2d6a91;

  border-radius: 10px;
}



.progress-bar-custom {
  height: 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.star {
  color: #ffcc00;
  font-size: 20px;
}



.options-list li {
  list-style: none;
  background-color: #185a84;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.options-list li:hover {
  background-color: #1c6a93;
}

.options-list {
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
}

.option-box {
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
}

.option-box:hover {
  background-color: #f0f0f0;
}

.option-box.selected {
  background-color: #28a745; /* Green color for selected option */
  color: #fff;
  border-color: #28a745;
}


.image-container {
  position: relative;
}

.image-container img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 150px;
}


@media (max-width: 576px) {
 
  .wrappers{
    margin-top: 88px;
    background-color: #2d6a91;
    width: 100%;
  }
}

/* Action */
/* certificates  */

.topbar{
  background-color: #0c384f;
  padding: 16px 0;
  text-align: center;
}
.topbar h1{
  color: #fff;
}
.topbar span{
  color: #00c4ff;
  font-size: 700;
}

.certificate{
  background-color: #0c384f;
  margin: 18px 0;
  text-align: center;
}
.certificate h2{
  color: #fff;
}
.certificate .container{
  background-color: #1a5071;
  margin: 16px 0;
}

.certificate span{
  color: #00c4ff;
  font-size: 700;
}


.certificate strong{
  color: #65c44b;
}

.btn-certificate{
  background-color: #ffcc00 !important;
  color: #fff !important;
  width: 100% !important;
  font-size: 18px !important;
  padding: 6px 0px !important;
  margin-bottom: 12px !important;
}

.certificate h5{
  color: #65c44b;
}

.certificate i{
  color: #65c44b;
  font-size: 22px;
}


.cards{
  padding: 12px 0;
  margin: 12px 0;
  background-color: #0c384f;
 
}

.card-content{
  margin-top: 10px;
  background-color: #1a5071;
  padding: 12px 24px;
}

.card-content .card-title{
  color: #00c4ff !important;
}

.cert-list  li{
  margin: 12px 0;
  background-color: #0c384f;
  padding:  12px 16px ;
  font-size: 18px;
  list-style: none;
}
.cert-list  li i {

color: #00c4ff ;
  
}

.faq-card{
  background-color: #0c384f;
}

.accordion{
  width: 80%;
  margin: 14px auto;
  padding: 14px 0;
  background-color: #1a5071;
}

.accordion-item {
  background-color: #0c384f !important;
}

/*  */

.cert-my-auto{
  background-color: #1a5071;
  width: 80%;
  padding: 12px;
}
.cert-my-auto .btn-certiId{
  background-color: #00c4ff !important;
  padding: 6px 33px;
  
}


/* === ecpololler */

.explore-container {
  padding: 20px;
  text-align: center;
}

.course-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.course-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;
  overflow: hidden;
}

.course-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.course-title {
  font-size: 1.5rem;
  margin: 15px 0 10px;
}

.course-description {
  font-size: 1rem;
  color: #666;
  margin: 0 15px 15px;
}

.explore-button {
  background-color: #F2EB0A;
  color: #111;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}



/* inquiry form */

.course-inquiry-form-container {
  margin: 22px 0;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.course-inquiry-form-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

.btn-course {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #F2EB0A;
  color: #111;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}





/* ============ */

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 28px;
  }
  .wrappers{
    padding: 0 28px;
   
  }
}

.company h1,h2, p{
  color: #fff !important;
}

.modal .modal-body p{
  color: #185a84 !important;
}

.card-text {
  color: #111 !important;
}